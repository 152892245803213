
import { defineComponent } from 'vue'
import { lBlog, lBvCat } from '@/api/blog' // @ is an alias to /src
import DtV from 'primevue/dataview'
import PnMn from 'primevue/panelmenu'
import Pgn from 'primevue/paginator'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: { DtV, PnMn, Pgn },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      blogcat: { title: 'Blog' },
      lQuery: {
        page: 1,
        lang: 'vi',
        slug: ''
      },
      totalItemsCount: 0,
      layout: 'list',
      lbaiviets: [],
      mnitems: []
    }
  },
  created () {
    const cmslug: any = this.$route.params.dm ? this.$route.params.dm : 0
    let cmlg: any = this.$route.params.lg ? this.$route.params.lg : ''
    if (cmlg !== '') {
      cmlg = sessionStorage.getItem('st-lg')
    }
    this.lQuery.lang = cmlg
    this.lQuery.slug = cmslug
    this.gList()
  },
  methods: {
    gList () {
      this.glbaiviets()
      this.glmenus()
    },
    glbaiviets () {
      lBlog(this.lQuery).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.data.data.forEach((item: any) => {
            tmp.push({
              key: item.id,
              title: item.title,
              nview: item.vcount,
              shortdesc: item.short_desc,
              url: '/' + this.lQuery.lang + '/blog/bai-viet/' + item.slug + '-' + item.code,
              image: res.data.url + '/' + item.image,
              cattitle: item.cattitle,
              created_at: item.acreated_at,
              caturl: '/' + this.lQuery.lang + '/blog/danh-muc/' + item.catslug + '-' + item.cat_id
            })
          })
          this.lbaiviets = tmp
          this.totalItemsCount = res.data.data.total
          if (res.data.catdata) {
            this.blogcat = res.data.catdata
            var seodt = { title: this.blogcat.title, description: this.blogcat.title, locale: this.lQuery.lang }
            document.title = this.blogcat.title + ' | Kpibsc.com'
          }
        }
        this.$emit('loadfooter')
      })
    },
    glmenus () {
      lBvCat({ lang: this.lQuery.lang }).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.forEach((item: any) => {
            tmp.push({ key: item.id, title: item.title, url: '/' + this.lQuery.lang + '/blog/danh-muc/' + item.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    },
    onPage (event: any) {
      this.lQuery.page = parseInt(event.page) + 1
      this.glbaiviets()
    }
  }
})
