
import { Options, Vue } from 'vue-class-component'
import { defineAsyncComponent } from 'vue'
import Button from 'primevue/button'
import DtV from 'primevue/dataview'
import PnM from 'primevue/panelmenu'
import Brc from 'primevue/breadcrumb'
import Pgn from 'primevue/paginator'
import Sklt from 'primevue/skeleton'
import { lBlog, lBvCat } from '@/api/blog'
import { gArticle } from '@/api/article'
@Options({
  components: { Button, DtV, PnM, Brc, Pgn, Sklt },
  data () {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      loading: false,
      breadcrumbs: [
        { label: 'Blog', to: '/blog' }
      ],
      url: '',
      lQuery: {
        page: 1,
        lang: 'vi',
        limit: '5',
        slug: ''
      },
      opct: {},
      layout: 'list',
      tiCount: 0,
      ldata: {},
      lsdatas: [],
      mnitems: []
    }
  },
  created () {
    const cmslug = this.$route.params.id ? this.$route.params.id : 0
    let cmlg = this.$route.params.lg ? this.$route.params.lg : ''
    if (cmlg !== '') {
      cmlg = sessionStorage.getItem('st-lg')
    }
    this.gList(cmslug)
  },
  methods: {
    gList (cmslug: any) {
      this.loading = true
      gArticle({ slug: cmslug }).then((res: any) => {
        if (res) {
          var tmp: any = []
          this.ldata = res.data.data
          var seodt: any = { title: this.ldata.title, description: this.ldata.short_desc }
          this.setSeo(seodt)
          this.url = res.data.url
          this.loading = false
          if (res.data.cat) {
            this.breadcrumbs.push({ label: res.data.cat.title, to: '/' + this.lQuery.lang + '/blog/danh-muc/' + res.data.cat.slug + '-' + res.data.cat.code })
            this.lQuery.slug = res.data.cat.slug + '-' + res.data.cat.code
            this.glbaiviets()
          }
        }
        this.$emit('loadfooter')
      })
      this.glmenus()
    },
    setSeo (dt: any) {
      var tmpmttitle = dt.title + ' | kpibsc.com'
      document.title = tmpmttitle
      var tmpdesc = dt.description
      const descEl = document.querySelector('head meta[name="description"]')
      if (descEl !== null) {
        descEl.setAttribute('content', tmpdesc)
      }
      const titleOg = document.querySelector('head meta[property="og:title"]')
      if (titleOg !== null) {
        titleOg.setAttribute('content', tmpmttitle)
      }
      const descOg = document.querySelector('head meta[property="og:description"]')
      if (descOg !== null) {
        descOg.setAttribute('content', tmpdesc)
      }
      const urlOg = document.querySelector('head meta[property="og:url"]')
      if (urlOg !== null) {
        urlOg.setAttribute('content', location.href)
      }
    },
    glbaiviets () {
      lBlog(this.lQuery).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.data.data.forEach((item: any) => {
            tmp.push({
              key: item.id,
              title: item.title,
              nview: item.vcount,
              shortdesc: item.short_desc,
              url: '/' + this.lQuery.lang + '/blog/bai-viet/' + item.slug + '-' + item.code,
              image: res.data.url + '/' + item.image,
              cattitle: item.cattitle,
              created_at: item.acreated_at,
              caturl: '/' + this.lQuery.lang + '/blog/danh-muc/' + item.catslug + '-' + item.cat_id
            })
          })
          this.lsdatas = tmp
          this.tiCount = res.data.data.total
        }
      })
    },
    opencontact (vl: any) {
      vl.pageid = this.$route.path
      vl.pagetitle = this.$route.meta.title
      this.$emit('opencontact', vl)
    },
    glmenus () {
      lBvCat({}).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.forEach((item: any) => {
            tmp.push({ key: item.id, title: item.title, url: '/' + this.lQuery.lang + '/blog/danh-muc/' + item.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    },
    onPage (event: any) {
      this.lQuery.page = parseInt(event.page) + 1
      this.glbaiviets()
    }
  }
})
export default class Blogdetail extends Vue {}
